import styled, { css } from 'styled-components'

import {
  primary,
  secondary,
  danger,
  text,
  cancel,
  outlinePrimary,
  outlineDanger,
  save
} from './buttons'

const baseButton = styled.button(({ disabled, width, ml, mt, mb, pd }) => ({
  borderRadius: 20,
  height: 40,
  minWidth: width || 100,
  border: 'none',
  padding: pd || '0 14px',
  textAlign: 'right',
  position: 'relative',
  cursor: 'pointer',
  marginLeft: ml | 0,
  marginTop: mt | 0,
  marginBottom: mb | 0,
  ':nth-child(2)': {
    marginLeft: '1em'
  },
  [disabled && css]: {
    ':disabled[disabled]': {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  }
}))

export const WrapperButton = styled(baseButton)(({ theme, variant }) => ({
  [variant === 'primary' && css]: {
    ...primary(theme)
  },
  [variant === 'outline-primary' && css]: {
    ...outlinePrimary(theme)
  },
  [variant === 'outline-danger' && css]: {
    ...outlineDanger(theme)
  },
  [variant === 'secondary' && css]: {
    ...secondary(theme)
  },
  [variant === 'danger' && css]: {
    ...danger(theme)
  },
  [variant === 'cancel' && css]: {
    ...cancel(theme)
  },
  [variant === 'text' && css]: {
    ...text(theme)
  },
  [variant === 'save' && css]: {
    ...save()
  }
}))

export const Content = styled.div(({ justify }) => ({
  display: 'flex',
  justifyContent: justify || 'center',
  alignItems: 'center'
}))

export const Children = styled.span(({ theme }) => ({
  letterSpacing: 0,
  fontSize: 16,
  fontFamily: ['Rubik', 'sans-serif'],
  fontWeight: theme.weight.medium,
  color: theme.color.gray6
}))

export const WrapperIcon = styled.span(({ mli }) => ({
  height: 26,
  fontSize: 22,
  marginLeft: mli
}))
