import styled, { css } from 'styled-components'

import { primary, grayscale, alert } from 'css/colors'
import { device } from 'css/utils'
import { fontWeight } from 'css/utils/theme'

export const Button = styled.button(() => ({
  borderRadius: 20,
  height: 40,
  minWidth: 136,
  border: 'none',
  padding: '0 14px',
  textAlign: 'center',
  cursor: 'pointer',
  position: 'absolute',
  bottom: 50,
  color: grayscale.gray6,
  background: primary.blue3,
  '&:hover': {
    opacity: 0.8
  },
  [device('lg')]: {
    bottom: 150
  }
}))

export const Content = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '90%',
  position: 'relative',
  margin: '0 auto',
  '& > button': {
    marginLeft: '0 !important'
  },
  [device('md')]: {
    width: '70%'
  },
  [device('lg')]: {
    width: '42%'
  }
}))

export const WrapperError = styled.div(() => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '& > img': {
    width: '100%'
  }
}))

export const Message = styled.div(({ openDialog }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: 12,
  margin: '0 auto',
  width: '90%',
  height: 'calc(100% - 68%)',
  color: alert.red1,
  [device('md')]: {
    fontSize: 16
  },
  [openDialog && css]: {
    fontSize: '12px !important'
  }
}))

export const WrapperOctopus = styled.div(({ bgcolor, size }) => ({
  background: bgcolor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: size || '100%',
  height: size || '100%',
  margin: '0 auto'
}))

export const WrapperMessage = styled.div(({ textColor }) => ({
  width: '70%',
  textAlign: 'center',
  '& > h3': {
    fontSize: 20,
    letterSpacing: 0,
    textAlign: 'center',
    color: textColor || grayscale.gray1,
    fontWeight: fontWeight.medium
  },
  '& > p': {
    fontSize: 18,
    letterSpacing: 0,
    textAlign: 'center',
    whiteSpace: 'normal',
    color: textColor || grayscale.gray2,
    fontWeight: fontWeight.regular
  }
}))

export const Image = styled.figure(() => ({
  margin: '24px 0',
  padding: 0,
  textAlign: 'center',
  width: '100%',
  '& > img': {
    width: '70%'
  }
}))
