import { gql, InMemoryCache, makeVar } from '@apollo/client'
import store from 'store'

import { CLIENT_ADDENDUMS, RtdAddendumProps } from 'app/views/clients/queries'
import { ContractRequestUid } from 'app/views/contracts/types'
import { ProductLineProps } from 'app/views/productLine/queries'
const getLocalSearch = store.get('searchs') || []

export const lastSearchVar = makeVar([...getLocalSearch])
export const clientAddendums = makeVar<RtdAddendumProps[]>([])
export const invalidFields = makeVar<InvalidFieldsProps>(
  {} as InvalidFieldsProps
)
export const contractRequest = makeVar<ContractRequestUid>(
  {} as ContractRequestUid
)
export const selectLine = makeVar<ProductLineProps>({} as ProductLineProps)
export const versions = makeVar<VersionsProps>({} as VersionsProps)
export const googleId = makeVar<string>(store.get('googleId') || '')

export const cache: any = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        profile: {
          keyArgs: false,
          merge(existing = { childRoles: [] }, incoming) {
            const { childRoles: newChildRoles } = incoming
            const { childRoles: oldChilRoles } = existing
            return {
              ...incoming,
              childRoles: [{ ...newChildRoles, ...oldChilRoles }]
            }
          }
        },
        lastSearch: {
          read() {
            return lastSearchVar()
          }
        },
        clientAddendums: {
          read() {
            return clientAddendums()
          }
        }
      }
    },
    RtdProduct: {
      fields: {
        data: {
          merge: (existing, incoming, { mergeObjects }) => {
            return mergeObjects(existing, incoming)
          }
        },
        bank: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          }
        }
      }
    },
    BankAccount: {
      fields: {
        movements: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          }
        }
      }
    },
    InvalidFields: {
      fields: {
        invalid: {
          read() {
            return invalidFields()
          }
        }
      }
    },
    Contract: {
      fields: {
        contract: {
          read() {
            return contractRequest()
          }
        }
      }
    },
    ProductLine: {
      fields: {
        line: {
          read() {
            return selectLine()
          }
        }
      }
    },
    Versions: {
      fields: {
        versions: {
          read() {
            return versions()
          }
        }
      }
    }
  }
})

//Query from cache apollo client using directive @client
export const GET_CLIENT_ADDENDUMS = gql`
  query GET_CLIENT_ADDENDUMS($id: Int!, $clientProductId: Int) {
    client(id: $id) @client {
      clientProducts(clientProductId: $clientProductId) {
        id
        ... on RtdProduct {
          id
          data {
            ...Addendums
          }
        }
      }
    }
  }
  ${CLIENT_ADDENDUMS}
`

type InvalidFieldsProps = {
  [k: string]: {
    value: string
    header: string
  } | null
}

type VersionsProps = {
  [k: string]: {
    [k: string]: string | number | any
  }
}
