import { CSSProperties } from 'styled-components'

import { Children } from './stylesButton'

export const hoverStyles = (bgcolor: string) => ({
  ':hover': {
    opacity: 0.8,
    background: bgcolor
  }
})

export const primary = (theme: CSSProperties) => ({
  background: theme.color.blue3,
  ...hoverStyles(theme.color.blue3)
})

export const save = () => ({
  background: '#30BC86',
  padding: '0.625rem 2rem 0.5625rem 2rem',
  borderRadius: '0.5rem',
  height: '2.4375rem',
  ...hoverStyles('#CCEFE5')
})

export const outlinePrimary = (theme: CSSProperties) => ({
  border: `1px solid ${theme.color.blue3}`,
  background: theme.color.gray6,
  [`${Children}`]: {
    color: theme.color.blue3
  }
})

export const outlineDanger = (theme: CSSProperties) => ({
  border: `1px solid ${theme.color.red1}`,
  background: theme.color.gray6,
  [`${Children}`]: {
    color: theme.color.red1
  }
})

export const secondary = (theme: CSSProperties) => ({
  background: theme.color.aqua2,
  ...hoverStyles(theme.color.aqua2)
})

export const danger = (theme: CSSProperties) => ({
  background: theme.color.red1,
  ...hoverStyles(theme.color.red1)
})

export const text = (theme: CSSProperties) => ({
  background: 'transparent',
  padding: 0,
  [`${Children}`]: {
    color: theme.color.blue3,
    fontSize: 15
  }
})

export const cancel = (theme: CSSProperties) => ({
  background: 'transparent',
  ':hover': {
    background: theme.hexToRGB(theme.color.blue4, '0.2')
  },
  [`${Children}`]: {
    color: theme.color.blue3
  }
})
