import { createGlobalStyle } from 'styled-components'
import { primary, secondary, tertiary, grayscale, alert } from 'css/colors'
import { hexToRGB, SizeCurrency, device } from 'css/utils'

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700
}

export const theme = {
  device,
  hexToRGB,
  light: true,
  boxShadow: `0 2px 20px ${hexToRGB(tertiary.purple3, '0.24')}`,
  sizeCurrency: SizeCurrency,
  weight: { ...fontWeight },
  color: {
    ...primary,
    ...secondary,
    ...tertiary,
    ...grayscale,
    ...alert
  }
}

export const GlobalStyle = createGlobalStyle(() => ({
  body: {
    margin: 0,
    padding: 0,
    height: '100vh',
    background: grayscale.gray5,
    fontFamily: ['Rubik', 'sans-serif']
  },
  button: {
    fontFamily: ['Rubik', 'sans-serif']
  },
  '#app': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  'div.ct-toast': {
    border: 'none !important',
    width: 400,
    boxShadow: 'none',
    padding: 0,
    borderRadius: 8
  },
  'div.ct-text-group': {
    width: '100%',
    marginLeft: 0,
    height: '100%'
  },
  'div.ct-text': {
    height: '100%'
  },
  '.hide-notify': {
    opacity: '0 !important',
    transition: '0.3s all ease-in-out !important'
  }
}))
