import React, { CSSProperties } from 'react'
import { mdiReload } from '@mdi/js'
import { useTheme } from 'styled-components'

import { WrapperOctopus, WrapperMessage, Image } from './styles'
import Button from 'components/button'

const OctopusMessage: React.FC<{
  headerMessage?: string
  message?: string
  image?: string
  size?: string | number
  bgcolor?: string
  textColor?: string
  style?: CSSProperties
  showButton?: boolean
  loading?: boolean
  onClick?: () => void
}> = ({
  message = 'No tienes permisos para ejecutar esta acción. Contacta a tu líder',
  headerMessage = '¡Ups, ocurrió un error!',
  image = '/assets/octopus.svg',
  size,
  bgcolor,
  textColor,
  style,
  onClick,
  showButton,
  loading
}) => {
  const theme = useTheme()
  return (
    <WrapperOctopus
      bgcolor={bgcolor}
      size={size}
      style={style}
      className="octopus"
    >
      <WrapperMessage textColor={textColor}>
        <h3>{headerMessage}</h3>
        <Image>
          <img src={image} alt="octopus" />
        </Image>
        <p>{message}</p>
        {showButton && (
          <Button
            hasPermissions
            variant="outline-primary"
            icon={mdiReload}
            colorIcon={theme.color.blue3}
            onClick={onClick}
            loading={loading}
          >
            Recargar
          </Button>
        )}
      </WrapperMessage>
    </WrapperOctopus>
  )
}
export default OctopusMessage
