import React from 'react'
import styled from 'styled-components'

const ErrorWrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: 2em 3em;
`
const Button = styled.button`
  margin-top: 20px;
  border: none;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
`
/**
 * @param {import('react-error-boundary').FallbackProps | {error:{message: string}}} Props
 */
const ErrorComponent = ({ error }) => {
  const handleErrror = () => {
    window.location.reload()
  }
  return (
    <ErrorWrapper>
      <h1>Ha ocurrido un error</h1>
      <p>
        No es tu culpa, por favor toma una captura de pantalla a esto y crea un
        ticket enviando un correo a <strong>soportetecnico@resuelve.io</strong>{' '}
        para poder reparar el error.
      </p>
      <p>
        Recuerda agregar la captura de pantalla y especificar los detalles de
        como podemos replicar el error.
      </p>
      <p>
        <strong>URL:</strong> {window.location.href}
      </p>
      <p>
        <strong>Hora:</strong> {new Date().toString()}
      </p>
      <p>
        <strong>Error:</strong> {error && error.message}
      </p>
      <Button onClick={handleErrror}>Intenta de nuevo</Button>
    </ErrorWrapper>
  )
}
export default ErrorComponent
