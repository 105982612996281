import { gql } from '@apollo/client'
import {
  Client,
  RtdProduct,
  PaginatedClients,
  RtdDebt,
  Movements,
  BankClient,
  ClientDocuments,
  RtdAddendum,
  MoneyAmount
} from './Client'

export declare type ClientProps = Client
export declare type BankClientProps = BankClient
export declare type RtdProductProps = RtdProduct
export declare type PaginatedClientProps = PaginatedClients
export declare type RtdDebtProps = RtdDebt
export declare type MovementProps = Movements
export declare type ClientDocumentsProps = ClientDocuments
export declare type RtdAddendumProps = RtdAddendum
export declare type MoneyAmountType = MoneyAmount

// Documentation fragments
// https://www.apollographql.com/docs/ios/fragments/#gatsby-focus-wrapper

// Documentation directive @client
// https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/

// This query is a fragment
export const CLIENT_ADDENDUMS = gql`
  fragment Addendums on RtdData {
    addendums {
      date
      monthlyPayment {
        new
        old
      }
      monthlyCommissionAmount {
        new {
          formattedAmount
        }
        old {
          formattedAmount
        }
      }
      monthlyFee {
        new
        old
      }
      monthlyPaymentDay {
        new
        old
      }
      programDuration {
        new
        old
      }
      totalDebtAmount {
        new {
          formattedAmount
        }
        old {
          formattedAmount
        }
      }
      settlementPlan {
        new
        old
      }
    }
  }
`

// This query is a fragment
export const CLIENT_DEBTS = gql`
  fragment Debts on RtdData {
    debts {
      creditLimit {
        formattedAmount
      }
      lastPaidDate
      lastUpdateDate
      liquidatedWithCredit
      liquidatedWithStructuredPayments
      paymentToBank {
        formattedAmount
      }
      settlementAmount {
        formattedAmount
      }
      successCommission {
        formattedAmount
      }
      id
      financialEntity
      amount {
        formattedAmount
      }
      creditNumber
      state
    }
  }
`

// This query is a frgament
export const CLIENT_BANK = gql`
  fragment Bank on RtdProduct {
    bank {
      balance {
        formattedAmount
      }
      accounts {
        movements(filters: $filters) {
          data {
            amount {
              formattedAmount
            }
            transactionTime
            type
            description
          }
        }
        balance {
          formattedAmount
        }
      }
    }
  }
`

// This query is a fragment
export const CLIENT_DOCUMENTS = gql`
  fragment Documents on Client {
    documents {
      id
      typeLabel
      signedUrl(expireTime: $expireTime)
    }
  }
`

// This query is a fragment
export const CLIENT_PRODUCTS = gql`
  fragment ClientProducts on Client {
    clientProducts(clientProductId: $clientProductId) {
      id
      type
      documents {
        id
        typeLabel
        signedUrl(expireTime: $expireTime)
      }
      ... on RtdProduct {
        id
        uid
        insertedAt
        data {
          ...Debts
          ...Addendums
          reference
          url
          status
          created
          programDuration
          startDate
          creditRepairId
          initialCommission {
            formattedAmount
          }
          monthlyCommissionAmount {
            formattedAmount
          }
          resuelveAmount {
            formattedAmount
          }

          monthlyPaymentDay
        }
        savings {
          reference
          bankUid
          bank {
            balance {
              formattedAmount
            }
          }
        }
        productVersion {
          tag
          product {
            name
            productLine {
              code
              country {
                iso
              }
            }
          }
        }
      }
    }
  }
  ${CLIENT_DEBTS}
  ${CLIENT_ADDENDUMS}
`

export const SEARCH_CLIENTS = gql`
  query ($filter: String) {
    listClients(filter: $filter) {
      data {
        id
      }
    }
  }
`

export const GET_LIST_CLIENTS = gql`
  query GET_LIST_CLIENTS(
    $country: String
    $filter: String
    $productLine: String
    $startName: String
    $pagination: PaginationInput
  ) {
    listClients(
      country: $country
      filter: $filter
      productLine: $productLine
      startName: $startName
      pagination: $pagination
    ) {
      lastSearch @client
      data {
        id
        names
        surnames
        email
        mobilePhone
        birthPlace
        clientProducts {
          id
          productVersion {
            id
            product {
              id
              productLine {
                code
                name
                country {
                  iso
                }
              }
            }
          }
        }
      }
      pagination {
        pageNumber
        pageSize
        totalEntries
        totalPages
      }
    }
  }
`

export const GET_CLIENT_IFORMATION = gql`
  query GET_CLIENT_IFORMATION(
    $id: Int!
    $expireTime: Int
    $clientProductId: Int
  ) {
    client(id: $id) {
      id
      names
      email
      mobilePhone
      surnames
      identification
      identificationType
      taxIdentification
      taxIdentificationType
      birthPlace
      birthCountry {
        id
        name
        iso
      }
      addresses {
        city
        country
        interiorNumber
        municipality
        nearbyStreets
        neighborhood
        postalCode
        state
        street
        streetNumber
        title
      }
      ...Documents
      ...ClientProducts
    }
  }
  ${CLIENT_DOCUMENTS}
  ${CLIENT_PRODUCTS}
`

export const GET_CLIENT_RTD_PRODUCT = gql`
  query GET_CLIENT_RTD_PRODUCT(
    $id: Int!
    $clientProductId: Int!
    $filters: BankMovementsFilters
  ) {
    client(id: $id) {
      id
      clientProducts(clientProductId: $clientProductId) {
        ... on RtdProduct {
          id
          bankUid
          uid
          data {
            ...Debts @client
            ...Addendums @client
            reference
            url
            status
            created
            programDuration
            startDate
            initialCommission {
              formattedAmount
            }
            monthlyCommissionAmount {
              formattedAmount
            }
            resuelveAmount {
              formattedAmount
            }
            monthlyPaymentDay
          }
          ...Bank
        }
      }
    }
  }
  ${CLIENT_BANK}
  ${CLIENT_DEBTS}
  ${CLIENT_ADDENDUMS}
`

export const GET_CLIENT_RTD_PRODUCT_MOVEMENTS = gql`
  query GET_CLIENT_RTD_PRODUCT_MOVEMENTS(
    $id: Int!
    $clientProductId: Int!
    $filters: BankMovementsFilters
  ) {
    client(id: $id) {
      id
      clientProducts(clientProductId: $clientProductId) {
        ... on RtdProduct {
          id
          bank {
            accounts {
              uid
              movements(filters: $filters) {
                data {
                  amount {
                    formattedAmount
                  }
                  transactionTime
                  type
                  description
                }
                pagination {
                  pageNumber
                  totalEntries
                  totalPages
                }
              }
            }
          }
        }
      }
    }
  }
`
