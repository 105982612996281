import { lazy, Suspense } from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import store from 'store'
import ErrorBoundary from 'react-error-boundary'
import { ApolloProvider, ApolloClient } from '@apollo/client'

import Spinner from 'components/spinner'
import ErrorComponent from 'components/error'
import ErrorMessage from 'components/errorMessage'
import { GlobalStyle } from 'css/utils/theme'
import { cache } from 'utils/cache'
import { link } from 'utils/apolloConfig'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ToastContainer } from 'react-toastify'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || false

// we just initialize sentry when there is a valid DSN value
if (SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    // just captura 50% of data for performance monitoring
    tracesSampleRate: 0.5
  })
}

const Login = lazy(() => import('./login'))
const StackRoutes = lazy(() => import('routes'))

const client = new ApolloClient({
  cache,
  credentials: 'include',
  link
})

function PrivateRoute({ component: Component, ...rest }) {
  const hasToken = store.get('CORE_TOKEN')
  return (
    <Route
      {...rest}
      render={props =>
        hasToken ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  )
}

const Routes = () => {
  return (
    <ApolloProvider client={client}>
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <BrowserRouter>
          <GlobalStyle />
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/error" component={ErrorMessage} />
              <PrivateRoute path="/" component={StackRoutes} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </ErrorBoundary>
      <ToastContainer position="top-center" />
    </ApolloProvider>
  )
}
render(<Routes />, document.getElementById('app'))
