export const primary = {
  blue1: '#2D3662',
  blue2: '#2F4194',
  blue3: '#5373E9',
  blue4: '#98AAF2',
  blue5: '#CFD8FF',
  blue6: '#F0F3FF'
}

export const secondary = {
  aqua1: '#3399A2',
  aqua2: '#37C2CF',
  aqua3: '#8DF0F7',
  aqua4: '#CEFCFF',
  aqua5: '#E5FDFF',
  aqua6: '#F2FEFF'
}

export const tertiary = {
  purple1: '#434476',
  purple2: '#7060B2',
  purple3: '#9D90D1',
  purple4: '#D2C8E6',
  purple5: '#ECE6F6',
  purple6: '#F7F3FF'
}

export const grayscale = {
  gray1: '#404063',
  gray2: '#5F5F79',
  gray3: '#D0D0E6',
  gray4: '#EDEDF8',
  gray5: '#F6F6FF',
  gray6: '#FFFFFF'
}

export const alert = {
  red1: '#FA648D',
  red2: '#FF8E91',
  red3: '#FFECF3',
  red4: '#FFEEF0',
  yellow1: '#F6D44B',
  yellow2: '#FFE67F',
  yellow3: '#FFFCEE',
  orange1: '#FFAD4A',
  orange2: '#FFF7EE',
  orange3: '#FFF4E7',
  green1: '#30BC86',
  green2: '#7FEFC3',
  green3: '#E9FFF8',
  green4: '#31BC86',
  green5: '#D0FFED'
}

export const labels = {
  new: '#135AAD',
  approved: '#30BC86',
  sent: '#37C2CF',
  voided: '#DE2B5C',
  signed: '#96D4FF',
  declined: '#FA648D',
  pending_review: '#5373E9',
  in_review: '#FAD245',
  on_hold_by_saving_provider: '#FFAD4A',
  paused_review: '#ADAEB0',
  rejected: '#FA648D',
  canceled: '#9D1037',
  review: '#FAD245',
  pending: '#5373E9',
  hold: '#FFAD4A',
  paused: '#ADAEB0',
  partial: '#3bc9ad'
}

export const doku = {
  textBanner: '#384FA4',
  h3: '#434476',
  p: '#2B2E33',
  pGrey: '#6E6F73',
  pBlue: '#5373E9'
}
