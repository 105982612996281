import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import OctopusMessage from './OctopusMessage'

import { Content, Button } from './styles'

const ErrorMessage = () => {
  const history = useHistory()
  const location = useLocation<{ error: string }>()

  function handleBackHome() {
    history.push('/')
  }

  const message =
    location.state && location.state.error
      ? location.state.error
      : 'Ocurrio un error. Intenta de nuevo en unos minutos'

  const hasErrors = sessionStorage.getItem('errors')

  if (hasErrors) {
    const parseError = JSON.parse(hasErrors)
    console.error('sessionsErros', parseError)
  }

  return (
    <Content>
      <OctopusMessage message={message} />
      <Button onClick={handleBackHome}>Ir al inicio</Button>
    </Content>
  )
}
export default ErrorMessage
