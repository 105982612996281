import { onError } from '@apollo/client/link/error'
import { createHttpLink, split, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import * as AbsintheSocket from '@absinthe/socket'
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link'
import { Socket as PhoenixSocket } from 'phoenix'
import { RestLink } from 'apollo-link-rest'
import store from 'store'

const formSerializer = (data: any, headers: Headers) => {
  const formData = new window.FormData()
  Object.keys(data).map(key => {
    formData.append(key, data[key])
  })
  headers.set('Accept', '*/*')
  return { body: formData, headers }
}

const restLink = new RestLink({
  uri: `${process.env.REACT_APP_API_CONTRACTS}`,
  endpoints: {
    contracts: {
      uri: `${process.env.REACT_APP_API_CONTRACTS}`,
      responseTransformer: async response =>
        response.json().then(({ data }) => data)
    },
    contractsSampleDocuments: {
      uri: `${process.env.REACT_APP_API_CONTRACTS}`,
      responseTransformer: async response => {
        const res = await new Response(response.body)
        const blob = await res.blob()
        const newBlob = blob.slice(0, blob.size, 'application/zip')
        const url = URL.createObjectURL(newBlob)
        return { fileUrl: url }
      }
    },
    doku: {
      uri: `${process.env.REACT_APP_API_DOKU}`,
      responseTransformer: async response =>
        response.json().then(({ data }) => data)
    },
    dokuRenderPDF: {
      uri: `${process.env.REACT_APP_API_DOKU}`,
      responseTransformer: async response => {
        const res = await new Response(response.body)
        const blob = await res.blob()
        const newBlob = blob.slice(0, blob.size, 'application/pdf')
        const url = URL.createObjectURL(newBlob)
        return { fileUrl: url }
      }
    },
    dokuExportVersion: {
      uri: `${process.env.REACT_APP_API_DOKU}`,
      responseTransformer: async response => {
        const res = await new Response(response.body)
        const blob = await res.blob()
        const newBlob = blob.slice(0, blob.size, 'application/json')
        const url = URL.createObjectURL(newBlob)
        return { fileUrl: url }
      }
    },
    alv: {
      uri: `${process.env.REACT_APP_API_ALV}`
    },
    settlex: {
      uri: `${process.env.REACT_APP_API_SETTLEX}`,
      responseTransformer: async response =>
        response.json().then(({ data }) => data)
    }
  },
  headers: {
    authorization: store.get('NU_LOGIN_TOKEN')
  },
  bodySerializers: {
    sendMultipart: formSerializer
  }
})

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_CORE}`
})
const authLink = setContext((_, { headers }) => {
  const token = store.get('CORE_TOKEN')
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
})
const setContextauth = authLink.concat(httpLink)

const phoenixSocket = new PhoenixSocket(`${process.env.REACT_APP_WS_CORE}`, {
  params: () => {
    if (store.get('CORE_TOKEN')) {
      return { Authorization: `Bearer ${store.get('CORE_TOKEN')}` }
    } else {
      return {}
    }
  }
})
const absintheSocket = AbsintheSocket.create(phoenixSocket)
const websocketLink = createAbsintheSocketLink(absintheSocket)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  websocketLink,
  setContextauth
)

export const link = ApolloLink.from([
  onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.map(({ message, code }: any) => {
        if (code === 401) {
          sessionStorage.setItem('errors', code)
          window.alert('No cuentas con autorización. Inicia sesión')
          store.clearAll()
          window.location.replace(window.location.origin)
        }
        handleError('message', `${message && message}`)
      })
    } else if (graphQLErrors) {
      handleError(
        'graphQLErrors',
        `[graphQLErrors]: ${graphQLErrors && graphQLErrors}`
      )
    }
  }),
  restLink,
  splitLink
])

const handleError = (error: string, message: string): void => {
  const errors = sessionStorage.getItem('errors')
  const hasErrors = errors
    ? {
        prevError: { ...JSON.parse(errors) },
        error: message
      }
    : { error: message }

  sessionStorage.setItem('errors', `${JSON.stringify(hasErrors)}`)
  console.error(`[${error}]: ${message}`)
}
