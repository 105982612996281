import React from 'react'
import Icon from '@mdi/react'
import { mdiLoading } from '@mdi/js'

import { WrapperButton, WrapperIcon, Content, Children } from './stylesButton'
import { grayscale } from 'css/colors'

const Button: React.FC<ButtonProps> = ({
  width,
  disabled,
  children,
  type = 'button',
  className,
  onClick,
  loading,
  onBlur,
  name,
  testId,
  onKeyUp,
  onMouseDown,
  tabIndex,
  variant,
  icon,
  iconSize = 1,
  justify,
  style,
  colorIcon = grayscale.gray6,
  hasPermissions,
  rotateIcon,
  sending,
  ml,
  mt,
  mb,
  pd,
  mli = 7
}) => {
  if (!hasPermissions) return null
  return (
    <WrapperButton
      ml={ml}
      mt={mt}
      mb={mb}
      width={width}
      type={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
      data-testid={`button-${testId}`}
      name={name}
      tabIndex={tabIndex}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
      variant={variant}
      style={style}
      pd={pd}
    >
      <Content justify={justify}>
        <Children variant={variant}>
          {sending ? (
            <Icon
              title="Loading"
              rotate={rotateIcon}
              path={mdiLoading}
              size={iconSize}
              spin={loading || sending}
              color={colorIcon}
            />
          ) : (
            children
          )}
        </Children>
        {icon && (
          <WrapperIcon mli={mli}>
            <Icon
              title="Loading"
              rotate={rotateIcon}
              path={loading ? mdiLoading : icon}
              size={iconSize}
              spin={loading}
              color={colorIcon}
            />
          </WrapperIcon>
        )}
      </Content>
    </WrapperButton>
  )
}
export default Button

interface ButtonProps {
  disabled?: boolean
  children?: React.ReactNode
  type?: 'submit' | 'button' | 'reset'
  className?: string
  onClick?: (event?: any) => void | any
  loading?: boolean
  onBlur?: () => void | any
  name?: string
  testId?: string
  onKeyUp?: (event: any) => void | any
  onMouseDown?: () => void | any
  tabIndex?: number
  icon?: string
  iconSize?: number
  justify?: string
  style?: any
  colorIcon?: string
  hasPermissions: boolean
  sending?: boolean
  width?: number | string
  ml?: number | string
  mt?: number | string
  mb?: number | string
  rotateIcon?: number
  variant?: Variant
  pd?: number | string
  mli?: number | string
}

export type Variant =
  | 'primary'
  | 'outline-primary'
  | 'outline-danger'
  | 'secondary'
  | 'danger'
  | 'cancel'
  | 'text'
  | 'save'
